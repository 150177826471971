import "../../Main/Desktop/Payment/Payment.css"
import { Elements } from "@stripe/react-stripe-js";
import Wrapper from "../../Wrapper/Wrapper";
import PaymentSvg from "./PaymentSvg";

const Payment = ({
  isPaymentDone,
  clientSecret,
  stripePromise,
  options,
  setClientSecret,
  currency,
  subscriptions,
  prices,
  setIsPaymentDone,
  totalPrice,
  isLoading,
}) => {
  return (
    <>
      <div
        className={`payment-container ${
          isPaymentDone === false && clientSecret ? "shown" : ""
        }`}
      >
        {isPaymentDone === false && clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <button
              onClick={() => setClientSecret(null)}
              className='return-button'
            >
              <span style={{ transform: "translateY(-1px)", fontSize: 16 }}>
                ←
              </span>
              Return to home
            </button>
            <Wrapper
              currency={currency}
              priceId={null}
              autoPay={false}
              name={subscriptions[0].name}
              email={subscriptions[0].email}
              duration={null}
              prices={prices}
              isPaymentDone={isPaymentDone}
              setIsPaymentDone={setIsPaymentDone}
              clientSecret={clientSecret}
              totalPrice={totalPrice}
              bulk={true}
              orders={subscriptions}
            />
          </Elements>
        )}
      </div>
      <div
        className={`payment-container-done ${
          isPaymentDone && isLoading === false ? "shown" : ""
        }`}
      >
        <div className='success-div'>
          <div class='animation-ctn'>
            <div class='icon icon--order-success svg'>
              <PaymentSvg />
            </div>
          </div>
          {isPaymentDone && isLoading === false && (
            <>
              <h2 style={{ textAlign: "center", marginTop: 60 }}>
                Payment successful.
              </h2>
              <p style={{ textAlign: "center", marginTop: 60 }}>
                You should receive your invitation email within a few minutes.
              </p>
              <p style={{ textAlign: "center", marginTop: 10 }}>
                If any problem, contact us on Telegram:
              </p>
              <h3 style={{ textAlign: "center", marginTop: 20 }}>
                @cheapccnet
              </h3>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Payment;
