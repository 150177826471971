const DurationSelect = ({ duration, setDuration }) => {
    const handleDurationChange = (e) => {
      setDuration(parseFloat(e.target.value))
    }
    return (
      <>
        <div className='input-div'>
          <label>Duration of your license:</label>
          <select
            value={duration}
            onChange={handleDurationChange}
          >
            <option value={30}>1 month</option>
            <option value={365}>1 year</option>
            <option value={730}>2 years</option>
            <option value={1095}>3 years</option>
          </select>
        </div>
      </>
    );
  };
  
  export default DurationSelect;
