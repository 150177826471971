import { useState } from "react"

const DurationSelect = ({ duration, setDuration }) => {
    const handleDurationChange = (e) => {
      setDisabledActive(false)
      setDuration(parseFloat(e.target.value))
    }
    const [disbledActive, setDisabledActive] = useState(true)
    return <>
    <div className='input-div-mobile'>
      {/* <label style={{ textAlign: "left", fontSize: 16, marginTop:0 }}>
        Duration of your license:
      </label> */}
      <select
        style={{ fontSize: 16, backgroundColor: "white", border:"1px solid #c9c9c9", height:35 }}
        value={duration}
        onChange={handleDurationChange}
      >
        {disbledActive && <option disabled value={30}>Duration of your license...</option>}
        <option value={30}>1 month</option>
        <option value={365}>1 year</option>
        <option value={730}>2 years</option>
        <option value={1095}>3 years</option>
      </select>
    </div>
    </>
}

export default DurationSelect
