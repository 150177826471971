import { useState, useRef } from "react"
import { Tooltip as ReactTooltip } from "react-tooltip";

const DisplayApps = ({
    style,
    columns=3,
    apps
}) => {

    const [searchTerm, setSearchTerm] = useState("");
    const [showAll, setShowAll] = useState(false);
    const maxHeight = 400;
    const appsRef = useRef(null)

    const handleShowLess = () => {
        window.scrollTo({top: appsRef.current.getBoundingClientRect().top + document.documentElement.scrollTop - 400 })
        setShowAll(false);
    }

    const filteredApps = apps.filter(app => app.name.toLowerCase().includes(searchTerm.toLowerCase()));

    const appGridStyle = {
        display: "grid", 
        gridTemplateColumns: "repeat("+columns+", 1fr)", 
        gap: "1rem", 
        marginTop: 30,
        transition:'0.3s',
        maxHeight: showAll ? "2000px" : maxHeight,
        overflow: showAll ? "visible" : "hidden"
    };

    return (
        <div style={{ padding: "1rem", ...style }}>
            <div className="input-wrapper" ref={appsRef}>
                <input
                    type="text"
                    placeholder={"Search among " + apps.length +" applications..."}
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    style={{ width: "100%" }}
                />
            </div>
            <div style={appGridStyle}>
                {filteredApps.map((app) => (
                    <>
                        <div
                            style={{ display: "flex", alignItems: "center", position: "relative", cursor:"help" }}
                            data-tooltip-id={"tt-"+app.name}
                        >
                            <img src={app.icon} alt={app.name} style={{ height: "1.3rem", width: "1.3rem", marginRight: "0.5rem" }} />
                            <span style={{ fontSize: "0.875rem", color: 'black', textAlign: 'left', fontWeight: '600' }}>{app.name}</span>
                        </div>
                        <ReactTooltip
                            delayShow="0"
                            id={"tt-" + app.name}
                            place="bottom"
                            content={app.description}
                            style={{zIndex:999, maxWidth:300, backgroundColor:'black', borderRadius:10 }}
                        />
                    </>
                ))}
            </div>
            {!showAll && filteredApps.length * 20.799 > maxHeight && (
                <div 
                    style={{
                        position: "relative",
                        marginTop: "-20px",
                        width:"100%",
                        height:20,
                        textAlign:"center",
                        cursor: "pointer",
                    }}
                    onClick={() => setShowAll(true)}
                >
                    <div style={{
                        position: "absolute",
                        bottom: 0,
                        color:"#3c7ede",
                        left: -10,
                        right: 0,
                        padding: "5px 0",
                        backgroundColor: "white",
                        width:"calc(100% + 20px)",
                        boxShadow: filteredApps.length>6?"0px -20px 30px 25px rgb(255, 255, 255)":"none",
                        borderRadius: "0 0 5px 5px"
                    }}>
                        See more
                    </div>
                </div>
            )}
            {showAll && (
                <div style={{marginTop: "2rem", color:"#3c7ede", cursor:'pointer' }} onClick={handleShowLess}>Minimize</div>
            )}
        </div>
    );
}

export default DisplayApps
