import AutoPayPopUp from "./AutoPayPopUp";
import SubscriptionForm from "./SubscriptionForm/SubscriptionForm";
import Faq from "./Faq";
import Footer from "../../Footer/Footer";
const Desktop = ({
  isHovered,
  apps,
  isMobile,
  getSvgPosition,
  handleSubmit,
  clientSecret,
  formSubmittedAutoPay,
  name,
  setName,
  duration,
  setDuration,
  email,
  setEmail,
  isAutoPay,
  setIsAutoPay,
  svgRef,
  handleMouseEnter,
  handleMouseLeave,
  currency,
  prices,
  AdobePrices,
  country,
  isLoading,
  isPaymentDone,
  stripePromise,
  options,
  setClientSecret,
  setIsPaymentDone,
  setFormSubmittedAutoPay,
  faqShown,
  ip,
  ttclid,
  setFaqShown,
  dashBoard,
  userAgent,
}) => {
  return (
    <div className="main">
      {isHovered && <AutoPayPopUp getSvgPosition={getSvgPosition} />}
      <div className="main-main">
        <div className="main-main-main">
          <SubscriptionForm
            userAgent={userAgent}
            handleSubmit={handleSubmit}
            clientSecret={clientSecret}
            formSubmittedAutoPay={formSubmittedAutoPay}
            name={name}
            ip={ip}
            ttclid={ttclid}
            isMobile={isMobile}
            setName={setName}
            duration={duration}
            setDuration={setDuration}
            email={email}
            setEmail={setEmail}
            isAutoPay={isAutoPay}
            setIsAutoPay={setIsAutoPay}
            svgRef={svgRef}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            currency={currency}
            prices={prices}
            AdobePrices={AdobePrices}
            country={country}
            dashBoard={dashBoard}
            isLoading={isLoading}
          />
        </div>

        <Faq faqShown={faqShown} setFaqShown={setFaqShown} apps={apps} />
      </div>
      <Footer />
    </div>
  );
};

export default Desktop;
