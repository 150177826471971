import "../Faq.css"
import DisplayApps from "../DisplayApps/DisplayApps";

const Faq = ({
  moreDetails,
  apps
}) => {
  return (
    <>
      <div ref={moreDetails} className='faq-mobile'>
        <h1>More details</h1>
        <h3 className='faq-mobile-title'>What is CheapCC?</h3>
        <p>
          CheapCC.net is a site where you can submit a request 
          for the use of unused Adobe licenses by schools verified by Adobe.
        </p>
        <h3 className='faq-mobile-title'>What is included?</h3>
        <p>
          Here's the full list of what is included: all Adobe applications
          (except Substance 3D), 80GB cloud storage, Firefly AI with 250 credits
          renewed every month, access to beta apps, and 2 active simultaneous
          desktop sessions + with 2 mobile sessions. It's the genuine and
          official subscription, no need to use a VPN.
        </p>
        <h3 className='faq-mobile-title'>
          Where does the subscription come from?
        </h3>
        <p>
        We have a partnership with many organizations/schools around the world 
        that have unused Adobe licenses and are happy to help people in need.
        Our partner private schools are British, American, Egyptian and Chinese,
        and we accept applicants from everywhere on earth.
        </p>
        <p>
        Your Adobe subscription is applied to your own Adobe account 
        via an e-mail invitation from one of these schools.
        </p>
        <h3 className='faq-mobile-title'>Why can't I place an order?</h3>
        <p>
        Due to the nature of these subscriptions, their number is limited. 
        Therefore, if all slots are taken, orders are closed.
        Once a spot is purchased, it's locked for the duration paid.
        </p>
        {/* <h3 className='faq-mobile-title'>
          How can I be sure that my 3-year subscription will really last 3
          years?
        </h3>
        <p>
        Legal agreements bind CheapCC and its partner organizations. 
        Once your application has been approved, you will receive a letter from the school.
        This letter certifies access to your Adobe license for the duration you have selected.
        </p> */}
        <h3 className='faq-mobile-title' style={{ marginTop: 30 }}>
          Does anyone has access to my content?
        </h3>
        <p>
          We DO NOT have access to your content created in the Adobe
          applications. Only Adobe has potential access, particularly when using
          online features, such as generative AI. Here's an article about the
          recent updates to Adobe's terms of use on their access to user
          content:
        </p>
        <a
          style={{ color: "#596b76", maxWidth: "-webkit-fill-available" }}
          target='_blank'
          rel='noreferrer'
          href='https://blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use'
        >
          blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use
        </a>
        <h3 className='faq-mobile-title' style={{ marginTop: 30, marginBottom:10 }}>What applications are included?</h3>
          <DisplayApps apps={apps} columns={2}/>
      </div>
    </>
  );
};

export default Faq;
