import '../AutoPayPopUp.css'

const AutoPayPopUp = ({ getSvgPosition }) => {

  return <>
    <div
      className='question-div'
      style={{
        position: "fixed",
        ...getSvgPosition(),
        zIndex: 999,
        width: "230px",
        backgroundColor: "black",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Enable auto-pay if you want your order to be automatically renewed.
      <br></br>
      You can switch it off anytime, but you cannot switch it on once your order is confirmed.
    </div>
  </>
}

export default AutoPayPopUp
