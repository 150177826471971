import "../../css/App.css";
import "../../css/Media.css";
import "./Main.css";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import Desktop from "./Desktop/Desktop";
import Mobile from "./Mobile/Mobile";
import { priceHelper } from "./helper";
import constants from "../../constants";
import InfoLoginBar from "./InfoLoginBar/InfoLoginBar";
const STRIPE_PUBLIC_KEY = constants.STRIPE_PUBLIC_KEY;
const API_URL = constants.API_URL;
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
const {
  price: { _prices, _AdobePrices },
} = priceHelper;

function Main() {
  const [faqShown, setFaqShown] = useState(false);
  const [currency, setCurrency] = useState("€");
  const [country, setCountry] = useState("DE");
  const [ip, setIp] = useState('')
  const [prices] = useState(_prices);
  const [AdobePrices] = useState(_AdobePrices);
  const [name, setName] = useState("");
  const [isAutoPay, setIsAutoPay] = useState(true);
  const [email, setEmail] = useState(null);
  const [duration, setDuration] = useState(30);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [formSubmittedAutoPay, setFormSubmittedAutoPay] = useState(false);
  const [dashBoard, setDashBoard] = useState(false);
  const [apps, setApps] = useState([])
  const isMobile = useMediaQuery("(max-width: 668px)");

  const isMobileCancelled =
    new URLSearchParams(window.location.search).get("cancel") === "1" &&
    isMobile;

  const ttclid = (new URLSearchParams(window.location.search).get("ttclid")) ? new URLSearchParams(window.location.search).get("ttclid") : ""

  const userAgent = window.navigator.userAgent

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("cancel") === "1") {
      if (isMobile) {
        // window close may not work on mobile devices
        const win = window.open("","_self"); /* url = "" or "about:blank"; target="_self" */
        win.close();
      } else {
        window.close();
      }
    }
    if (params.get("name")) {
      setName(params.get("name"))
    }
    if (params.get("email")) {
      setEmail(params.get("email"))
    }
    if (params.get("duration")) {
      setDuration(parseInt(params.get("duration")))
    }
    if (params.get("renewal")) {
      setIsAutoPay(params.get("renewal")===true)
    }
  }, [isMobile]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://extreme-ip-lookup.com/json/?key=uxJZ5TPQRYbhGqTVc6DI"
        );
        const data = await response.json();
        const country = data.countryCode;
        setIp(data.query)
        setCurrency(country === "US" ? "$" : country === "PH" ? "₱" : "€");
        setCountry(country);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [setCurrency, setCountry]);

  useEffect(() => {
    if (isMobile && isPaymentDone) {
      const { top } = mobileMain.current.getBoundingClientRect();
      window.scrollTo({
        top: top + document.documentElement.scrollTop - 15,
        behavior: "smooth",
      });
    }
  }, [isPaymentDone, isMobile]);

  useEffect(() => {
    fetch(API_URL+"/v2/applications")
      .then(res => res.json())
      .then(data => setApps(data.apps));
  }, []);

  const onScroll = useCallback((e) => {  
    if (isHovered === true) {  
      setIsHovered(false);  
    }  
  }, [isHovered]);
    
  useEffect(() => {  
    window.addEventListener('scroll', onScroll);  
    return () => {  
      window.removeEventListener('scroll', onScroll);  
    };  
  }, [onScroll]);


  const triggerRerender = () => {
    setName("");
    setIsAutoPay(false);
    setEmail(null);
    setIsLoading(false);
    setIsPaymentDone(false);
    setClientSecret(null);
    setIsHovered(false);
    setFormSubmittedAutoPay(false);
    setDashBoard(false);
  };
  const svgRef = useRef(null);
  const moreDetails = useRef(null);
  const mobileMain = useRef(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const goToMoreDetails = () => {
    const { top } = moreDetails.current.getBoundingClientRect();
    window.scrollTo({
      top: top + document.documentElement.scrollTop - 15,
      behavior: "smooth",
    });
  };

  const returnToHome = () => {
    if (isAutoPay) {
      setFormSubmittedAutoPay(false);
    }
    if (isAutoPay === false || isAutoPay === "false") {
      setClientSecret(null);
    }
    if (isMobile) {
      const { top } = mobileMain.current.getBoundingClientRect();
      window.scrollTo({
        top: top + document.documentElement.scrollTop - 15,
        behavior: "smooth",
      });
    }
  };

  const getSvgPosition = () => {
    if (svgRef.current && !isMobile) {
      const { x, y, width } = svgRef.current.getBoundingClientRect();
      return { top: y - 20, left: x - width + 50 };
    }
    if (svgRef.current && isMobile) {
      const { x, y, width } = svgRef.current.getBoundingClientRect();
      return { top: y + 30, left: x - width + 110 }
    }
    return { top: 0, left: 0 };
  };

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#1c53de",
    },
  };

  const options =
    isAutoPay === false
      ? { clientSecret, appearance, business: "ASM" }
      : {
          mode: "setup",
          currency: currency === "$" ? "usd" : "eur",
          appearance,
          business: "ASM",
        };

  return (
    <>
      {isMobileCancelled ? (
        <main
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            backgroundColor: "black",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999999999,
          }}
        >
          <p style={{ fontSize: "20px", color: "white" }}>
            Please close this tab to return to the homepage
          </p>
        </main>
      ) : null}
      <div className="App">
        <div className="logo-div">
          <div  
            style={{  
              width: "100%",  
              justifyContent: "center",  
              maxWidth: "500px",
            }}  
            className="logo"  
            alt="cheapcc-logo"  
          />
        </div>
        <InfoLoginBar
          setDashBoard={setDashBoard}
          dashBoard={dashBoard}
          triggerRerender={triggerRerender}
        />
        {(isMobile === "false" || isMobile === false) && (
          <Desktop
            userAgent={userAgent}
            ip={ip}
            ttclid={ttclid}
            apps={apps}
            isHovered={isHovered}
            getSvgPosition={getSvgPosition}
            clientSecret={clientSecret}
            formSubmittedAutoPay={formSubmittedAutoPay}
            name={name}
            setName={setName}
            duration={duration}
            setDuration={setDuration}
            email={email}
            setEmail={setEmail}
            isAutoPay={isAutoPay}
            setIsAutoPay={setIsAutoPay}
            svgRef={svgRef}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            prices={prices}
            AdobePrices={AdobePrices}
            isLoading={isLoading}
            isPaymentDone={isPaymentDone}
            stripePromise={stripePromise}
            options={options}
            setClientSecret={setClientSecret}
            currency={currency}
            isMobile={isMobile}
            country={country}
            setIsPaymentDone={setIsPaymentDone}
            setFormSubmittedAutoPay={setFormSubmittedAutoPay}
            faqShown={faqShown}
            setFaqShown={setFaqShown}
            dashBoard={dashBoard}
          />
        )}
        {isMobile && (
          <Mobile
            userAgent={userAgent}
            ip={ip}
            ttclid={ttclid}
            goToMoreDetails={goToMoreDetails}
            moreDetails={moreDetails}
            mobileMain={mobileMain}
            isHovered={isHovered}
            getSvgPosition={getSvgPosition}
            clientSecret={clientSecret}
            formSubmittedAutoPay={formSubmittedAutoPay}
            name={name}
            apps={apps}
            setName={setName}
            duration={duration}
            setDuration={setDuration}
            email={email}
            isMobile={isMobile}
            setEmail={setEmail}
            isAutoPay={isAutoPay}
            setIsAutoPay={setIsAutoPay}
            svgRef={svgRef}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            prices={prices}
            AdobePrices={AdobePrices}
            isLoading={isLoading}
            isPaymentDone={isPaymentDone}
            stripePromise={stripePromise}
            options={options}
            currency={currency}
            country={country}
            setIsPaymentDone={setIsPaymentDone}
            returnToHome={returnToHome}
            dashBoard={dashBoard}
          />
        )}
      </div>
    </>
  );
}

export default Main;
