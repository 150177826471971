import {
  CardNumberElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import "./Wrapper.css";
import SpinnerRed from "./Card/SpinnerRed";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import CardNumber from "./Card/CardNumber";
import ExpiryDate from "./Card/ExpiryDate";
import CVVcode from "./Card/CVVcode";
import Email from "./Card/Email";
import Name from "./Card/Name";
import TypePaymentInfo from "./Card/TypePaymentInfo";
import Total from "./Card/Total";
import ButtonPayNow from "./Card/ButtonPayNow";
import axios from "axios";
import toast from "react-hot-toast";
import constants from "../../constants";
import { format } from "date-fns";

const Wrapper = ({
  setIsPaymentDone,
  email,
  bulk,
  totalPrice,
  name,
  autoPay,
  orders,
  duration,
  currency,
  mobileMain,
  prices,
  country,
  priceId,
  clientSecret,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState(null);
  const [autopayInvoiceUrl, setAutopayInvoiceUrl] = useState(null);
  const [invoiceButtonReady, setInvoiceButtonReady] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width: 668px)");

  useEffect(() => {
    if (isMobile) {
      window.scrollTo({ top: mobileMain - 15, behavior: "smooth" });
    }
  }, [isMobile, mobileMain]);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    if (
      (autoPay === false || autoPay === "false") &&
      (bulk === false || bulk === "false")
    ) {
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
    }
  }, [stripe, autoPay, clientSecret, bulk]);

  const createInvoice = async () => {
    setInvoiceLoading(true);
    setInvoiceUrl(null)
    setInvoiceButtonReady(false)
    try {
      const response = await axios.post(
        constants.API_URL + "/payment/create-invoice",
        {
          name,
          email,
          duration,
          autopay:autoPay,
          priceId: priceId,
          currency: currency.split(':')[0],
          purchase_date: format(new Date(), "yyyy-MM-dd"),
        }, {
        withCredentials: true
      })
      setInvoiceUrl(response.data.url)
      setInvoiceButtonReady(true)
      setInvoiceLoading(false);
    }
    catch (error) {
      setInvoiceLoading(false);
      setInvoiceUrl(null)
      setInvoiceButtonReady(false)
      console.error(error)
      setMessage("Error: " + error.message)
    }
  }

  const createSubscription = async (e) => {
    setIsPaymentLoading(true);
    try {
      const paymentMethod = await stripe.createPaymentMethod({
        card: elements.getElement(CardNumberElement),
        type: "card",

      });

      const response = await axios.post(
        constants.API_URL + "/payment/create-subscription",
        {
          name,
          email,
          duration,
          paymentMethod: paymentMethod.paymentMethod,
          priceId: priceId,
          currency: currency.split(':')[0],
          purchase_date: format(new Date(), "yyyy-MM-dd"),
        }, {
        withCredentials: true
      }
      );
      if (response.statusText !== "OK")
        return setMessage("Payment unsuccessful!");
      const data = response.data;
      setAutopayInvoiceUrl(data.invoice.hosted_invoice_url)
      const confirm = await stripe.confirmCardPayment(data.clientSecret, {
        payment_method: paymentMethod.paymentMethod.id,
        return_url: "https://cheapcc.net/success",
      });
      if (confirm.error) {
        toast.error("Payment unsuccessful!");
        setIsPaymentLoading(false);
        return setMessage("Payment unsuccessful!");
      }
      setMessage("Payment Successful! Subscription active.");
      setIsPaymentDone(true);
      setIsPaymentLoading(false);
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message || err?.message);
      setMessage("Payment failed! " + err.message);
      setIsPaymentLoading(false);
    }
  };

  const handlePaymentSubmit = async (e) => {
    elements.submit();
    e.preventDefault();

    if (!stripe) {
      return;
    }

    setIsPaymentLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://cheapcc.net/success",
      },
      redirect: "if_required",
    });

    if (error) {
      setIsPaymentDone(false);
      if (error.type === "card_error" || error.type === "validation_error") {
        toast.error(error.message);
        setMessage(error.message);
      } else {
        toast.error("An unexpected error occurred.");
        setMessage("An unexpected error occurred.");
      }
    } else {
      setMessage("Payment successful!");
      setIsPaymentLoading(true);
      if (bulk === true || bulk === "false") {
        const response = await fetch("/.netlify/functions/send-webhook", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orders: orders,
          }),
        });
        if (!response.ok)
          return setMessage("Problem while sending bulk orders.");
        else {
          setIsPaymentDone(true);
        }
      }
      setIsPaymentDone(true);
    }

    setIsPaymentLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
    appearence: {
      theme: "stripe",
    },
    business: { name: "ASM" },
  };

  return (
    <div className="payment-form">
      {autoPay === false && (
        <PaymentElement id="payment-element" options={paymentElementOptions} />
      )}
      {autoPay && (
        <div className="payment_wrapper" style={{ gap: 15, display: "flex" }}>
          <CardNumber />
          <div
            className="payment_subwrapper"
            style={{ display: "flex", flexDirection: "row", gap: 15 }}
          >
            <ExpiryDate />
            <CVVcode />
          </div>
        </div>
      )}
      <div style={{ marginTop: 50 }}>
        <Email message={message} email={email} />
        <Name name={name} />
        <div className="bar" />
        <TypePaymentInfo
          isMobile={isMobile}
          currency={currency.split(':')[1]}
          prices={prices}
          country={country}
          duration={duration}
          totalPrice={totalPrice}
          autoPay={autoPay}
          bulk={bulk}
        />
        <div className="bar"></div>
        <Total
          currency={currency.split(':')[1]}
          bulk={bulk}
          prices={prices}
          duration={duration}
          country={country}
          totalPrice={totalPrice}
        />
      </div>

      <p style={{ margin: "40px 0px" }}>
        You will instantly receive a confirmation e-mail. When your Adobe
        subscription is delivered, a second e-mail containing all instructions
        will be sent to your inbox. Usually, subscriptions are delivered instantly.
      </p>

      <div style={{ display: "flex", flexDirection: "column", marginTop: 20 }}>
        {autoPay===false && <div>
          <p style={{ color: invoiceLoading === false && invoiceButtonReady===true?'#007025':'red', display: 'flex', flexDirection: 'row', gap: 10 }}>{invoiceLoading === false && invoiceButtonReady===true ? "The invoice has been sent to your inbox." : (invoiceLoading === true && invoiceButtonReady===false ? "":"Payment not working?")}
            {invoiceLoading === false && invoiceButtonReady===false && <span onClick={createInvoice} style={{ textDecoration: "underline", cursor: "pointer" }}>Click here</span>}
            {invoiceLoading === true && invoiceButtonReady===false && <SpinnerRed />}
            {invoiceLoading === false && invoiceButtonReady===true && <a style={{textDecoration:'underline', cursor:'pointer'}} target="_blank" rel="noreferrer" href={invoiceUrl}>Go to invoice</a>}
          </p>
        </div>}
        {autoPay===true && autopayInvoiceUrl && <div>
          <p style={{ color: invoiceLoading === false && invoiceButtonReady===true?'#007025':'red', display: 'flex', flexDirection: 'row', gap: 10 }}>
            Payment not working?
            <a style={{textDecoration:'underline', cursor:'pointer'}} target="_blank" rel="noreferrer" href={autopayInvoiceUrl}> Try using this link</a>
          </p>
        </div>}
        <ButtonPayNow
          autoPay={autoPay}
          createSubscription={createSubscription}
          handlePaymentSubmit={handlePaymentSubmit}
          isPaymentLoading={isPaymentLoading}
          stripe={stripe}
        />
        <img
          draggable={false}
          src="/images/badge.png"
          style={{
            width: "250px",
            marginTop: 30,
            userSelect: "none",
            alignSelf: "center",
            justifySelf: "center",
          }}
          alt="stripe-badge"
        />
      </div>
    </div>
  );
};
export default Wrapper;
