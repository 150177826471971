import "../../SubscriptionForm.css";
import { useState } from "react";
import FullNameInput from "./Input/FullName";
import EmailInput from "./Input/Email";
import DurationSelect from "./Select/Duration";
import AutoPaySelect from "./Select/AutoPay";
import Price from "./Price";
import PayButton from "./Button/PayButton";

import axios from "axios";
import toast from "react-hot-toast";
import constants from "../../../../constants";

const isTestEnv = process.env.REACT_APP_STRIPE_PUBLIC_KEY.startsWith("pk_test");

const SubscriptionForm = ({
  handleSubmit,
  isMobile,
  clientSecret,
  formSubmittedAutoPay,
  name,
  setName,
  duration,
  setDuration,
  email,
  setEmail,
  isAutoPay,
  setIsAutoPay,
  svgRef,
  handleMouseEnter,
  handleMouseLeave,
  currency,
  userAgent,
  prices,
  AdobePrices,
  country,
  ttclid,
  ip,
  dashBoard,
  isLoading,
}) => {
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const handleCheckout = async () => {
    setIsCheckoutLoading(true);
    let priceId = isTestEnv
      ? prices["default"][duration].test_id
      : prices["default"][duration].prod_id;

    if (country === "PH") {
      priceId = isTestEnv
        ? prices["PH"][duration].test_id
        : prices["PH"][duration].prod_id;
    }

    if (country === "US") {
      priceId = isTestEnv
        ? prices["US"][duration].test_id
        : prices["US"][duration].prod_id;
    }

    try {
      const { data } = await axios.post(
        constants.API_URL + "/payment/create-checkout-session",
        {
          priceId:priceId,
          name: name,
          email: email,
          ip: ip,
          userAgent:userAgent,
          is_auto_pay: isAutoPay,
          is_mobile: isMobile,
          currency: currency,
          price: prices[country]?prices[country][duration].number:prices["default"][duration].number,
          ttclid: ttclid
        },
        {
          withCredentials: true,
        }
      );
      if (isMobile) {
        location.href = data.url;//eslint-disable-line
      }
      else {
        window.open(data.url, "_blank");
      }
      setIsCheckoutLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      setIsCheckoutLoading(false);
    }
  };
  return (
    <>
      <div
        className={`inputs ${
          !clientSecret && formSubmittedAutoPay === false && !dashBoard
            ? "shown"
            : ""
        }`}
      >
        {!clientSecret && formSubmittedAutoPay === false && !dashBoard && (
          <div className="inputs-subdiv">
            <h1 style={{ marginBottom: 0, textAlign: "left" }}>
              Submit an application
            </h1>
            <p>
              In a rush? All applications are now reviewed & delivered
              instantly.
              <br></br>
              <br></br>
              Our partner schools will invite you to join their team. If
              you accept, you will have access to all Adobe applications (except
              Substance 3D), 80GB cloud storage, Firefly AI with 250 credits
              renewed every month, access to beta apps, and 2 active
              simultaneous sessions (with 2 mobile sessions as well).
              It's not geo-blocked, and of course our partner schools don't have access to any of your data.
            </p>
            <div className="input-container">
              <FullNameInput name={name} setName={setName} />
              <EmailInput email={email} setEmail={setEmail} />
              <DurationSelect duration={duration} setDuration={setDuration} />
              <AutoPaySelect
                isAutoPay={isAutoPay}
                setIsAutoPay={setIsAutoPay}
                svgRef={svgRef}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
              />
            </div>
            <div>
              <Price
                currency={currency}
                country={country}
                prices={prices}
                duration={duration}
                AdobePrices={AdobePrices}
              />
            </div>
            <PayButton
              email={email}
              name={name}
              clientSecret={clientSecret}
              formSubmittedAutoPay={formSubmittedAutoPay}
              isLoading={isCheckoutLoading}
              onClick={handleCheckout}
            />
            <img
              draggable={false}
              src="/images/stripe-logo.png"
              style={{
                width: "125px",
                userSelect: "none",
                alignSelf: "center",
                justifySelf: "center",
                position: "absolute",
                bottom: "20px",
                right: "20px",
              }}
              alt="stripe-badge"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SubscriptionForm;
