import React from "react";
import axios from "axios";
import toast from "react-hot-toast";
import constants from "../../../constants";
import "./downloadInvoice.css";
import Spinner from "../../Common/Spinner";

function base64ToBlob(base64String, contentType = '') {
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
  }

  const byteArray = new Uint8Array(byteArrays);
  return new Blob([byteArray], { type: contentType });
}


export default function DownloadInvoice({ id }) {
  const [loading, setLoading] = React.useState(false);
  const handleInvoiceClick = async () => {
    try {
      setLoading(true)
      const res = await axios.get(
        constants.API_URL + "/invoice/" + id,
        {
          withCredentials: true,
        }
      );
      if (res.status === 200) {
        const blob = base64ToBlob(res.data.data, 'application/pdf')
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL, res.data.filename);
        toast.success("Invoice downloaded successfully.");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
          {loading ? (
            <span className="switchBtn" style={{ alignItems: "center" }}>
              <Spinner height={17} width={17} color={"#64748b"} />
            </span>
          ) : (
            <>
              <span
                type="button"
                className="switchBtn"
                onClick={handleInvoiceClick}
              >
                Invoice
              </span>
            </>
          )}
    </>
  );
}
