import React from "react";
import "./CancelSubscription.css";
import Spinner from "../../Common/Spinner";
import toast from "react-hot-toast";
import constants from "../../../constants";
import axios from "axios";
import { Sleep } from "../../../utils";
export default function CancelSubscription({ id, onCancel, fetchUserOrders }) {
  const [loading, setLoading] = React.useState(false);
  const handleCancellation = async () => {
    try {
      setLoading(true);
      const res = await axios.patch(
        constants.API_URL + "/payment/cancel-subscription/" + id,
        {},
        {
          withCredentials: true,
        }
      );
      if (res.status === 200) {
        toast.success("Subscription canceled successfully!");
        fetchUserOrders();
        await Sleep(800);
        onCancel();
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div style={{ padding:10, display:'flex', flexDirection:"row", gap:20, alignItems:'center' }}>
      <p style={{ paddingLeft:20 }}>
        Disabling auto-pay will cancel automatic renewal. 
        Once disabled, you cannot enable it back. <b>Are you sure you want to
        continue?</b>
      </p>
      <div className="cancel-btn-container">
        {loading ? (
          <Spinner height={17} width={17} color={"#64748b"} />
        ) : (
          <>
            <button className="cancel-button" onClick={onCancel}>
              Cancel
            </button>
            <button className="proceed-button" onClick={handleCancellation}>
              Proceed
            </button>
          </>
        )}
      </div>
    </div>
  );
}
