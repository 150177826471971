import { useEffect, useState } from "react";
import "./Dashboard.css";
import Collapsible from "react-collapsible";
import axios from "axios";
import constants from "../../constants";
import { getRemainigDays, getSubsDuration } from "../../utils";
import { format } from "date-fns";
import useAuthStore from "../../store/authStore";
import CancelSubscription from "./CancelSubscription/CancelSubscription";
import InfoLoginBar from "../Main/InfoLoginBar/InfoLoginBar";
import { useNavigate } from "react-router-dom";
import DownloadInvoice from './DownloadInvoice/DownloadInvoice'
import SwitchEmailForm from "./SwitchEmail/SwitchEmail";
import Spinner from "../Common/Spinner";
import { Sleep } from "../../utils";
import Footer from '../Footer/Footer'

function Dashboard() {

  const [orders, setOrders] = useState([]);
  const [seed, setSeed] = useState(Math.random());
  const auth = useAuthStore((state) => state.auth);
  const [isRefreshing, setIsRefreshing] = useState(false)
  async function fetchUserOrders() {
    try {
      const res = await axios.get(constants.API_URL + "/orders", {
        withCredentials: true,
      });
      const data = res.data?.data;

      setOrders(data || []);
      setSeed(Math.random());
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchUserOrders();
  }, []);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    fetchUserOrders();
    await Sleep(800)
    setIsRefreshing(false);
  }

  const navigate = useNavigate();
  return (
    <main className="App">
      <div className="logo-div">
        <img
          style={{ display: "flex", width: "100%", justifyContent: "center", maxWidth:500 }}
          draggable={false}
          src='images/logo.png'
          className="logo"
          alt="cheapcc-logo"
        />
      </div>

      <InfoLoginBar />
      <section className="dashboard-container">
        <div className="dashboard-content-wrapper">
          <button onClick={() => navigate("/")} className="buttonNav gray">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-chevron-left"
            >
              <path d="m15 18-6-6 6-6" />
            </svg>
            Home
          </button>
          <div className="dashboar-hero-text-wrapper" style={{ justifyContent:'space-between' }}>
            <div style={{ display:'flex', flexDirection:"row", alignItems:'flex-end', gap:20 }}>
              <p className="dashboard-hero-text">Your orders</p>
              <span>{`(${auth?.email})`}</span>
            </div>
            <button onClick={handleRefresh} style={{ marginBottom:58, outline:'none', backgroundColor:'#3c7ede', border:0, padding:'5px 10px', color:'white', borderRadius:500, cursor:'pointer' }}>{isRefreshing?<Spinner height={10} />:"Refresh"}</button>
          </div>
          <Table orders={orders} key={seed} fetchUserOrders={fetchUserOrders} />
        </div>
      </section>
      <Footer style={{marginBottom:50}} />
    </main>
  );
}

const Table = ({orders, key, fetchUserOrders}) => {
  
  return (
    <>
      <TableHeader />
      <TableRow key={key} orders={orders} fetchUserOrders={fetchUserOrders} />
    </>
  );
};
const TableHeader = () => {
  return (
    <div className="tableHeader">
      <div className="tableCellWidthId">ID</div>
      <div className="tableCellWidthStatus">Status</div>
      <div className="tableCellWidthDuration">Duration</div>
      <div className="tableCellWidthAutopay">Autopay</div>
      <div className="tableCellWidthEmail">E-mail address</div>
      <div className="tableCellWidthAction">{""}</div>
    </div>
  );
};

const TableRow = ({ orders, fetchUserOrders }) => {
  return (
    <>
      {orders.map((data, index) => (
        <TableContentCollapse data={data} index={index} fetchUserOrders={fetchUserOrders} />
      ))}
    </>
  );
};
const TableContentCollapse = ({ data, fetchUserOrders, index }) => {
  const [newEmail, setNewEmail] = useState(data.console_email)
  const [open, setOpen] = useState(false);
  const [menus, setMenus] = useState({
    cancel_sub: false,
    switch_email: false,
  });
  const handleMenuToggle = (field, value) => {
    setMenus({
      ...menus,
      [field]: value,
    });
  };
  return (
    <Collapsible
      onTriggerOpening={() => setOpen(!open)}
      onTriggerClosing={() => setOpen(!open)}
      transitionTime={200}
      easing={"cubic-bezier(.17,.67,.48,.99)"}
      trigger={<TableRowContent data={data} open={open} />}
    >
      <CollapsibleItem
        data={data}
        menus={menus}
        newEmail={newEmail}
        index={index}
        setNewEmail={setNewEmail}
        fetchUserOrders={fetchUserOrders}
        handleMenuToggle={handleMenuToggle}
        title={"Activation e-mail address"}
        value={data?.console_email}
      />
      <CollapsibleItem
        data={data}
        menus={menus}
        fetchUserOrders={fetchUserOrders}
        handleMenuToggle={handleMenuToggle}
        title={"Days left"}
        value={getRemainigDays(data.expiration_date)}
      />
      <CollapsibleItem
        data={data}
        menus={menus}
        fetchUserOrders={fetchUserOrders}
        handleMenuToggle={handleMenuToggle}
        title={"Order date"}
        value={format(data?.purchase_date, "dd MMM yy")}
      />
      <CollapsibleItem
        data={data}
        menus={menus}
        fetchUserOrders={fetchUserOrders}
        handleMenuToggle={handleMenuToggle}
        title={"Assigned school/organization"}
        value={
          data.organization || (
            <span title="Order expired or canceled!">N/A</span>
          )
        }
      />
      <CollapsibleItem
        data={data}
        menus={menus}
        fetchUserOrders={fetchUserOrders}
        handleMenuToggle={handleMenuToggle}
        title={"Total paid"}
        value={`${data.currency === "usd" ? "$" : "€"} ${
          data.amount
        } ${data.currency.toString().toUpperCase()}`}
      />
        <div className="collapsible-action-container">
          {/* <button className="downloadBtn">Download receipt</button> */}
          {!menus.cancel_sub && (
            <>
              
              {data.autopay && (
                <button
                  onClick={() => handleMenuToggle("cancel_sub", true)}
                  className="cancelBtn"
                >
                  Disable autopay{" "}
                </button>
              )}
            </>
          )}
          {menus.cancel_sub && (
            <CancelSubscription
              id={data.id}
              onCancel={() => handleMenuToggle("cancel_sub", false)}
              fetchUserOrders={fetchUserOrders}
            />
          )}
        </div>
    </Collapsible>
  );
};
const TableRowContent = ({ data, open }) => {
  return (
    <div className="tableRow">
      <div className="tableRowFlex">
        <div className="tableCellWidthId"><p> # {data.id}</p> </div>
        <div className="tableCellWidthStatus">
          <DisplayStatus status={data.status==="Cancelled"?"Canceled":data.status} />
        </div>
        <div className="tableCellWidthDuration">
          <p>{getSubsDuration(data.duration)}</p> 
        </div>
        <div className="tableCellWidthAutopay">
          <DisplayAutopay autopay={data.autopay} />
        </div>
        <div className="tableCellWidthEmail"><p>{data?.console_email}</p></div>
        <div className={`tableCellWidthAction `}>
          <span
            style={{ display: "inline-block" }}
            className={` ${open ? "rotate-90" : "revert-90"}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#3b82f6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-chevron-right"
            >
              <path d="m9 18 6-6-6-6" />
            </svg>
          </span>
        </div>
      </div>
    </div>
  );
};

const DisplayStatus = ({ status }) => {
  return (
    <p className={`displayStatus`} style={{ color:'black' }}>
      <span
        className={`
          statusCircle ${
            status === "active"
              ? "green"
              : status === "cancelled"
              ? "red"
              : "black"
          }
          `}
      />
      {status}
    </p>
  );
};
const DisplayAutopay = ({ autopay }) => {
  return (
    <p className={`displayStatus`} style={{ color:'black' }}>
      <span
        className={`
          statusCircle ${autopay ? "green" : "red"}
          `}
      />
      {autopay ? "On" : "Off"}
    </p>
  );
};

const CollapsibleItem = ({ title, value, data, handleMenuToggle, menus, fetchUserOrders, setNewEmail, newEmail }) => {

  function cancel() {
    setNewEmail(data.console_email)
    handleMenuToggle("switch_email", false)
  }

  return (
    <>
      <div className="collapse-content">
        <div className="collapse-content item" style={{ paddingBottom:(title==="Activation e-mail address" && data.status==="active")?"0px":"" }}>
          <p style={{ fontWeight: 600 }}>{title}</p>
          <p>
            {(title!=="Activation e-mail address" || data.status!=="active") && value}
            {data.status === "active" && title==="Activation e-mail address" && (
              <>
                <input style={{ width:'fit-content', height:12, borderRadius:6 }} placeholder="New e-mail" onChange={(e)=>(setNewEmail(e.target.value))} value={newEmail} disabled={!menus.switch_email} className={`email-change-input ${!menus.switch_email?"disabled":"enabled"}`} />
                {!menus.switch_email && (<span
                  onClick={() => handleMenuToggle("switch_email", true)}
                  className="switchBtn"
                >
                  Change
                </span>)}
                {menus.switch_email && (
                  <SwitchEmailForm
                    cancel={cancel}
                    id={data.id}
                    email={newEmail}
                    onCancel={() => handleMenuToggle("switch_email", false)}
                    fetchUserOrders={fetchUserOrders}
                  />
                )}
                </>
            )}
            
            {title==="Total paid" && <DownloadInvoice id={data.id} />}
          </p>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
