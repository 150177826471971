import AutoPaySvg from "./AutoPaySvg"

const AutoPaySelect = ( { svgRef, handleMouseEnter, handleMouseLeave, isAutoPay, setIsAutoPay}) => {
    const handleAutopayChange = (e) => {
    setIsAutoPay(e.target.value === "true" || e.target.value === true)
  }
    return <>
    <div className='input-div-mobile'>
      {/* <label
        style={{
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          gap: 10,
          fontSize: 16,
          marginTop:0,
        }}
      >
        Enable auto-pay:
      </label> */}
      <select
        style={{ fontSize: 16, backgroundColor: "white", border:"1px solid #c9c9c9", height:35 }}
        value={isAutoPay}
        onChange={handleAutopayChange}
      >
        <option value={true}>Enable auto-pay (default)</option>
        <option value={false}>Disable auto-pay</option>
      </select>
      <AutoPaySvg svgRef={svgRef} handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} />
    </div>
    </>
}

export default AutoPaySelect;
