import axios from "axios";
import React from "react";
import toast from "react-hot-toast";
import constants from "../../../constants";
import Spinner from "../../Common/Spinner";
import "./switchEmail.css"

export default function SwitchEmailForm({ id, cancel, fetchUserOrders, email }) {
  const [loading, setLoading] = React.useState(false);
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const handleSwitchEmail = async () => {
    try {
      setLoading(true);
      if (!email) toast.error("Name and email are required!");
      if (validateEmail(email)) {
      const res = await axios.patch(
        constants.API_URL + "/orders/switch/" + id,
        {
          email: email,
          add_to_console: true,
        },
        {
          withCredentials: true,
        }
      );
      if (res.status === 200) {
        toast.success("Email switched successfully!");
        fetchUserOrders();
      }}
      else {
        toast.error("E-mail is invalid!");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div style={{ width: "100%", display:'flex', alignItems:"center", justifyContent:'center' }}>
        <div className="switch-email-input-container">
          {loading ? (
          <div className="cancel-btn-container" style={{ margin:0 }}>
            <button className="switchBtn">
              <Spinner height={17} width={17} color={"#7dd3fc"} />
            </button>
          </div>
        ) : (
          <div className="cancel-btn-container" style={{ margin:0, alignItems:"center", justifyContent:'center' }}>
            <span type="button" onClick={cancel} style={{ color:'#d92727', textDecoration:'underline', cursor:'pointer', fontSize:12 }}>
              Cancel
            </span>
            <button onClick={handleSwitchEmail} className="switchBtn">Switch</button>
          </div>
        )}
        </div>
    </div>
  );
}
