import "../../SubscriptionForm.css";
import { useState } from "react";
import PayButton from "./Button/PayButton";
import FullNameInput from "./Input/FullName";
import EmailInput from "./Input/Email";
import DurationSelect from "./Select/Duration";
import AutoPaySelect from "./Select/AutoPay";
import Price from "./Price";
import axios from "axios";
import toast from "react-hot-toast";
import constants from "../../../../constants";

const isTestEnv = process.env.REACT_APP_STRIPE_PUBLIC_KEY.startsWith("pk_test");

const SubscriptionForm = ({
  handleSubmit,
  ttclid,
  clientSecret,
  goToMoreDetails,
  formSubmittedAutoPay,
  name,
  setName,
  duration,
  setDuration,
  email,
  setEmail,
  isAutoPay,
  setIsAutoPay,
  svgRef,
  handleMouseEnter,
  handleMouseLeave,
  currency,
  prices,
  ip,
  userAgent,
  country,
  AdobePrices,
  dashBoard,
  isMobile,
}) => {
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const handleCheckout = async () => {
    setIsCheckoutLoading(true);
    let priceId = isTestEnv
      ? prices["default"][duration].test_id
      : prices["default"][duration].prod_id;

    if (country === "PH") {
      priceId = isTestEnv
        ? prices["PH"][duration].test_id
        : prices["PH"][duration].prod_id;
    }

    if (country === "US") {
      priceId = isTestEnv
        ? prices["US"][duration].test_id
        : prices["US"][duration].prod_id;
    }

    try {
      const { data } = await axios.post(
        constants.API_URL + "/payment/create-checkout-session",
        {
          priceId:priceId,
          name: name,
          email: email,
          ip: ip,
          userAgent:userAgent,
          is_auto_pay: isAutoPay,
          is_mobile: isMobile,
          currency: currency,
          price: prices[country]?prices[country][duration].number:prices["default"][duration].number,
          ttclid: ttclid,
        },
        {
          withCredentials: true,
        }
      );
      if (isMobile) {
        location.href = data.url;//eslint-disable-line
        
        console.log('here')
      }
      else {
        window.open(data.url, "_blank");
      }
      setIsCheckoutLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      setIsCheckoutLoading(false);
    }
  };
  return (
    <>
      <div
        className={`inputs-mobile ${
          !clientSecret && formSubmittedAutoPay === false && !dashBoard
            ? "shown"
            : ""
        }`}
      >
        {!clientSecret && formSubmittedAutoPay === false && !dashBoard && (
          <div className="inputs-subdiv-mobile">
            <h1 style={{ marginBottom: 0, textAlign: "left" }}>
              Submit an application
            </h1>
            <p style={{ fontSize: 16, marginBottom:0, marginTop: 30, backgroundColor:'#efefef', padding:10, borderRadius:6 }}>
              Submit an application to use our partner schools' unused
              Adobe licenses. Application reviewing process is automatic and instant.
              <br></br><br></br>
              See <span onClick={goToMoreDetails} style={{ textDecoration:'underline', cursor:'pointer' }}>More details</span> section for more inforomation.
            </p>
            <div className="input-container-mobile">
              <FullNameInput name={name} setName={setName} />
              <EmailInput email={email} setEmail={setEmail} />
              <DurationSelect duration={duration} setDuration={setDuration} />
              <AutoPaySelect
                svgRef={svgRef}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                isAutoPay={isAutoPay}
                setIsAutoPay={setIsAutoPay}
              />
            </div>
            <Price
              currency={currency}
              country={country}
              prices={prices}
              duration={duration}
              AdobePrices={AdobePrices}
            />
            <img
              draggable={false}
              src="/images/stripe-logo.png"
              style={{
                width: "125px",
                userSelect: "none",
                alignSelf: "center",
                justifySelf: "center",
                position: "absolute",
                bottom: "-10px",
                right: "-10px",
              }}
              alt="stripe-badge"
            />
            <PayButton
              email={email}
              name={name}
              clientSecret={clientSecret}
              formSubmittedAutoPay={formSubmittedAutoPay}
              isLoading={isCheckoutLoading}
              onClick={handleCheckout}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SubscriptionForm;
