import "./Operationnal.css";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
const Operationnal = ({ status }) => {
  return (
    <>
      <div id='tooltip' className="operational">
        <div className="light" style={{ backgroundColor: status?.status_color || "red" }}></div>{" "}
        {status?.status_text}
      </div>
      <Tooltip
        anchorSelect="#tooltip"
        place="bottom"
        style={{
          zIndex: 1000,
          maxWidth: '300px',
          opacity:1,
          backgroundColor:'#000',
          borderRadius:10
        }}
      >
        <div>
          {status?.status_description}
        </div>
      </Tooltip>
    </>
  );
};

export default Operationnal;
