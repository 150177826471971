import "../Faq.css";
import DisplayApps from "../DisplayApps/DisplayApps";

const Faq = ({ faqShown, setFaqShown, apps }) => {
  return (
    <>
      <div className='faqContainer'>
        <div
          onClick={() => setFaqShown(!faqShown)}
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            width: "calc(700px - 72px)",
            alignItems: faqShown ? "flex-start" : "center",
            justifyContent: "space-between",
          }}
        >
          <h2 style={{ fontSize: 40, fontWeight: 300 }}>More details</h2>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249.14 473.14" style={{ fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 25, transition: '0.2s', transform: faqShown ? "rotate(90deg)" : "" }} height='35px' width='35px'>
            <polyline class="cls-1" points="12.5 12.5 236.64 236.64 12.65 460.64" />
          </svg>
        </div>
        <div className={`faq-expand ${faqShown ? "shown" : ""}`}>
          <div className='faq-expand__content'>
            <h3>{"What is CheapCC.net?"}</h3>
            <p>
              {
                "CheapCC.net is a site where you can submit a request for the use of unused Adobe licenses by schools verified by Adobe."
              }
            </p>
            <h3>{"Where does the subscription come from?"}</h3>
            <p>
              {
                "We have a partnership with many organizations/schools around the world that have unused Adobe licenses and are happy to help people in need. Our partner private schools are British, American, Egyptian and Chinese, and we accept applicants from everywhere on earth."
              }
            </p>
            <p>
              {
                "Your Adobe subscription is applied to your own Adobe account via an e-mail invitation from one of these schools."
              }
            </p>
            <h3>{"Why can't I place an order?"}</h3>
            <p>
              {
                "New applications cannot be submitted if all license slots are taken. Once a spot is purchased, it's locked for the duration paid. For further assistance, contact our support team."
              }
            </p>
            {/* <h3>
              {
                "How can I be sure that my 3-year subscription will really last 3 years?"
              }
            </h3>
            <p>
              {
                "Legal agreements bind CheapCC and its partner organizations. Once your application has been approved, you will receive a letter from them certifying access to your Adobe license for the duration you have selected."
              }
            </p> */}
            <h3>{"Does anyone has access to my content?"}</h3>
            <p>
              {
                "We DO NOT have access to your content created in the Adobe applications. Only Adobe has potential access, particularly when using online features, such as generative AI. Here's an article about the recent updates to Adobe's terms of use on their access to user content:"
              }
            </p>
            {
              <a href='https://blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use' style={{ marginBottom:30, textDecoration:"none" }}>
                blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use
              </a>
            }
            
            <h3 style={{ marginBottom:10 }}>What applications are included?</h3>
            <DisplayApps apps={apps} columns={3}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
