const EmailInput = ({ email, setEmail }) => {
  return (
    <>
      <div className='input-div-mobile'>
        {/* <label style={{ textAlign: "left", fontSize: 16, marginTop:0 }}>
          E-mail address:
        </label> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div className='input-wrapper-mobile'>
            <input
              style={{ width: "100%", fontSize: 16 }}
              value={email}
              spellCheck={false}
              required
              onChange={(e) => setEmail(e.target.value)}
              placeholder='E-mail address...'
              type='email'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailInput;
