const FullNameInput = ({ name, setName }) => {
    return (
      <>
        <div className='input-div'>
          <label>Full name:</label>
          <div
            className='input-wrapper'
            style={{ maxWidth: "calc(100% - 40px)", minWidth: 280 }}
          >
            <input
              style={{ width: "100%", borderRadius: 5 }}
              value={name}
              spellCheck={false}
              required
              onChange={(e) => setName(e.target.value)}
              placeholder='James Bond'
              type='name'
            />
          </div>
        </div>
      </>
    );
  };
  
  export default FullNameInput;