const TypePaymentInfo = ({
  isMobile,
  currency,
  prices,
  country,
  duration,
  totalPrice,
  autoPay,
  bulk,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: 15,
        }}
      >
        <p
          style={{
            textWrap: "nowrap",
            width: "fit-content",
            margin: 0,
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            color: "#30313D",
          }}
        >
          {isMobile ? "Subtotal:" : "Creative Cloud All Apps - Group"}
        </p>
        <p
          style={{
            textWrap: "nowrap",
            width: "fit-content",
            margin: 0,
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            color: "#30313D",
          }}
        >
          {currency}
          {bulk === false
            ? (prices[country] ? prices[country][duration].number.toFixed(2):prices["default"][duration].number.toFixed(2))
            : totalPrice.toFixed(2)}{" "}
          {autoPay
            ? duration === 30
              ? "billed monthly"
              : duration === 365
              ? "billed yearly"
              : duration === 730
              ? "billed bi-yearly"
              : duration === 1095
              ? "billed tri-yearly"
              : ""
            : "(one-time payment)"}
        </p>
      </div>
    </>
  );
};

export default TypePaymentInfo;
