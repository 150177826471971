import AutoPaySvg from "./AutoPaySvg";
const AutoPaySelect = ({
  isAutoPay,
  setIsAutoPay,
  svgRef,
  handleMouseEnter,
  handleMouseLeave
}) => {

  const handleAutopayChange = (e) => {
    setIsAutoPay(e.target.value === "true" || e.target.value === true)
  }

  return (
    <>
      <div className='input-div'>
        <label
          style={{
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            gap: 10,
          }}
        >
          Enable auto-pay:
          <AutoPaySvg
            svgRef={svgRef}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
        </label>
        <select
          value={isAutoPay}
          onChange={handleAutopayChange}
        >
          <option value={true}>Yes (default)</option>
          <option value={false}>No</option>
        </select>
      </div>
    </>
  );
};

export default AutoPaySelect;
