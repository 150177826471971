const ReturnButtonSvg = () => {
  return (
    <svg
      style={{ marginRight: 10, transform: "translateY(0px)" }}
      height="12.5"
      viewBox="0 0 490 490"
      width="12.5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m332.668 490-250.037-245.004 250.037-244.996 74.701 76.493-171.967 168.503 171.967 168.511z" />
    </svg>
  );
};

export default ReturnButtonSvg;
