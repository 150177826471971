import PayButtonSvg from "./PayButtonSvg";

const PayButton = ({
  email,
  name,
  clientSecret,
  formSubmittedAutoPay,
  isLoading,
  onClick
}) => {
  return (
    <>
      <div className='pay-button-div' onClick={onClick}>
        <button className='button' disabled={!email || !name}>
          <span id='button-text'>
            {isLoading ? (
              <div className='spinner' id='spinner'>
                <PayButtonSvg />
              </div>
            ) : (
              "Select"
            )}
          </span>
        </button>
      </div>
    </>
  );
};

export default PayButton;