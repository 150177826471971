const Price = ({ currency, prices, duration, AdobePrices, country }) => {
    return (
      <div style={{ display:'flex', flexDirection:'column' }}>
        <h1 style={{ textWrap: "nowrap", width: "fit-content", marginBottom:10, userSelect:'text' }}>
          <span style={{ fontSize: 28 }}>{currency}</span> {prices[country]?prices[country][duration].number:prices["default"][duration].number}{" "}
          {duration !== 30 && (
            <span style={{ fontSize: 14, fontWeight: 400 }}>
              ({currency}{" "}
              {prices[country]?prices[country][duration].per_month:prices["default"][duration].per_month}
            {" "} per month)
            </span>
          )}
        </h1>
        <span style={{ fontSize:12, fontWeight:'lighter', width:'fit-content' }}>Tax included.</span>
        <p style={{ color: "red" }}>
          You save{" "}
          <b style={{ color: "red" }}>
            ≈ {currency} {AdobePrices[country]&&prices[country]?(AdobePrices[country][duration] - prices[country][duration].number).toFixed(2):(AdobePrices["default"][duration] - prices["default"][duration].number).toFixed(2)}
          </b>{" "}
          compared to normal prices.
        </p>
      </div>
    );
  };
  
  export default Price;