import React from "react";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import InfoLoginBar from "../Main/InfoLoginBar/InfoLoginBar";

function Refund() {

  const navigate = useNavigate();

  return (
    <div
      className='refund-container'
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gap: 30,
      }}
    >
      <div className='logo-div' style={{ maxWidth:500, justifySelf:'center' }}>
        <img style={{ display: 'flex', width: '100%', justifyContent: 'center' }} draggable={false} src='/images/logo.png' className='logo' alt='cheapcc-logo' />
      </div>
      <InfoLoginBar />
      <div className='main-refund' style={{ marginTop: 20 }}>
        <div style={{ width: '100%', justifyContent: 'flex-start' }}>
          <button
            onClick={() => navigate("/")}
            className="button gray"
            style={{ fontWeight: "bold" }}
          >
            <svg
              style={{ marginRight: 10, transform: "translateY(0px)" }}
              height="12.5"
              viewBox="0 0 490 490"
              width="12.5"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m332.668 490-250.037-245.004 250.037-244.996 74.701 76.493-171.967 168.503 171.967 168.511z" />
            </svg>
            Home
          </button>
        </div>
        <h1>Refund policy</h1>
        <p
          style={{
            textAlign: "left",
            width: "fit-content",
            alignSelf: "flex-start",
          }}
        >
          We understand that sometimes circumstances change, and you may need to reconsider a purchase.
          That's why we offer a hassle-free refund policy for recent orders.
        </p>
        <p
          style={{
            textAlign: "left",
            width: "fit-content",
            alignSelf: "flex-start",
          }}
        >
          You can receive a 95% refund on any order placed within the last 72 hours.
          No need to provide a reason, simply request a refund and we'll process it promptly.
          We retain 5% to cover processing and administrative fees.
        </p>
        <p
          style={{
            textAlign: "left",
            width: "fit-content",
            alignSelf: "flex-start",
          }}
        >
          After the initial 72-hour period, refunds are generally not available for our digital subscriptions and products.
          These are designed to provide ongoing value and access, making refunds impractical after a certain point.
        </p>
        <p
          style={{
            textAlign: "left",
            width: "fit-content",
            alignSelf: "flex-start",
          }}
        >
          However, we recognize that exceptional situations can arise.
          If you have a unique circumstance beyond the 72-hour window, please contact us.
          We will review your request and consider exceptions on a case-by-case basis.
        </p>
        <p
          style={{
            textAlign: "left",
            width: "fit-content",
            alignSelf: "flex-start",
          }}
        >
          If you have an inquiry about anything, please contact us on Telegram (@cheapccnet) or by email (help@cheapcc.net).
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Refund;
