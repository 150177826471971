const FullNameInput = ({ name, setName }) => {
  return (
    <>
      <div className='input-div-mobile' style={{marginTop:10}}>
        {/* <label style={{ textAlign: "left", fontSize: 16 }}>Full name:</label> */}
        <div className='input-wrapper-mobile'>
          <input
            style={{ width: "100%", fontSize: 16 }}
            value={name}
            spellCheck={false}
            required
            onChange={(e) => setName(e.target.value)}
            placeholder='Full name...'
            type='name'
          />
        </div>
      </div>
    </>
  );
};

export default FullNameInput;
