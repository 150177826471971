const priceHelper = {
  price: {
    _prices: {
      default: {
        30: {
          number: 16,
          currency: "eur",
          prod_id: "price_1QY7Y0Avc7OJE1HgxeOLR8PY",
          test_id: "price_1QcSShAvc7OJE1HggY4NlfHn"
        },
        365: {
          number: 144,
          per_month: 12,
          currency: "eur",
          prod_id: "price_1Q0nLIAvc7OJE1HgEv3rHARq",
          test_id: "price_1QcSiGAvc7OJE1HgurGOtBCy"
        },
        730: {
          number: 240,
          per_month: 10,
          currency: "eur",
          prod_id: "price_1Q0nMSAvc7OJE1HgtkmQqjAN",
          test_id: "price_1QcSoGAvc7OJE1HgJSgNIBCL"
        },
        1095: {
          number: 320,
          per_month: 8.88,
          currency: "eur",
          prod_id: "price_1Q0nN2Avc7OJE1HgYFajWIaO",
          test_id: "price_1QcSpIAvc7OJE1Hg5dQYwqHP"
        }
      },
      US: {
        30: {
          number: 16,
          currency: "usd",
          prod_id: "price_1QY7YFAvc7OJE1HgX70HVE8B",
          test_id: "price_1QcSfzAvc7OJE1HgKge0OqoY"
        },
        365: {
          number: 144,
          per_month: 12,
          currency: "usd",
          prod_id: "price_1Q1oiuAvc7OJE1HgnEXnNZqw",
          test_id: "price_1QcSivAvc7OJE1HgON2VD3ZH"
        },
        730: {
          number: 240,
          per_month: 10,
          currency: "usd",
          prod_id: "price_1Q1oiPAvc7OJE1HgaFePnPAm",
          test_id: "price_1QcSojAvc7OJE1HggKdv9Hob"
        },
        1095: {
          number: 320,
          per_month: 8.88,
          currency: "usd",
          prod_id: "price_1Q1ohsAvc7OJE1Hg9A9ltmAz",
          test_id: "price_1QcSpmAvc7OJE1HgFWBQD81G"
        }
      },
      PH: {
        30: {
          number: 649,
          currency: "php",
          prod_id: "price_1QeHaiAvc7OJE1HgYLNwwRmU",
          test_id: "price_1QeHreAvc7OJE1Hg5mP0bamL"
        },
        365: {
          number: 6499,
          per_month: 542,
          currency: "php",
          prod_id: "price_1QeHd1Avc7OJE1HgQj7QBtuW",
          test_id: "price_1QeHqSAvc7OJE1HgTAErGcvS"
        },
        730: {
          number: 11999,
          per_month: 500,
          currency: "php",
          prod_id: "price_1QeHeoAvc7OJE1HgNhsO0ANQ",
          test_id: "price_1QeHspAvc7OJE1HgzxusH3bK"
        },
        1095: {
          number: 16999,
          per_month: 473,
          currency: "php",
          prod_id: "price_1QeHglAvc7OJE1HgI5ZqkbKf",
          test_id: "price_1QeHuVAvc7OJE1HgOsjYUSNP"
        }
      },
    },
    _AdobePrices: {

      default: {
        30: 100.51,
        365: 67.01*12,
        730: 67.01*24,
        1095: 67.01*36
      },
      US: {
        30: 89.99,
        365: 59.99*12,
        730: 59.99*24,
        1095: 59.99*36
      },
      PH: {
        30: 4336,
        365: 1495*12,
        730: 1495*24,
        1095: 1495*36
      },
    }
  }

};

export { priceHelper }
